export default [
  {
    image: "wordpress icon.svg",
    title: "WordPress Simplified",
    description:
      "One-click installer to initialize and configure WordPress from start to finish. One dashboard to mass-manage multiple WordPress instances.",
  },
  {
    image: "secure.svg",
    title: "Secure Against Attacks",
    description:
      "Hardens your site by default, further enhanced with the Toolkit’s No security expertisenecessary.",
  },
  {
    image: "storage.svg",
    title: "Stage and Test",
    description:
      "One-click installer to initialize and configure WordPress from start to finish. One dashboard to mass-manage multiple WordPress instances.",
  },

  {
    image: "automate.svg",
    title: "Run and Automate",
    description:
      "Singularly or mass-execute updates to the WP core, themes or plugins. Monitor and run all your WordPress sites from one dashboard.",
  },
  {
    image: "complexity.svg",
    title: "Cut Out Complexity",
    description:
      "One-click installer to initialize and configure WordPress from start to finish. One dashboard to mass-manage multiple WordPress instances.",
  },
  {
    image: "simple.svg",
    title: "Simple, but not Amateur",
    description:
      "Get full control with WP-CLI, maintenance mode, debug management, search engine index management and more.",
  },
];
