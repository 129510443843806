import React, { useCallback } from "react";
import PropTypes from "prop-types";

export default function Product(props) {
  const handleGetNowClick = useCallback((e) => {
    e.preventDefault();

    if (typeof window !== 'undefined') {
      window.gtag('config', 'AW-16624821405');
      var callback = function () {
        if (typeof(e.target.href) != 'undefined') {
          window.location = e.target.href;
        }
      };
      window.gtag('event', 'conversion', {
          'send_to': 'AW-16624821405/lVGXCM-Vw8MZEJ3Jqvc9',
          'value': props.price,
          'currency': 'USD',
          'event_callback': callback
      });
    } else {
      window.location.href = e.target.href;
    }
  }, [props.price]);
  
  return (
    <div className="planBox border-solid border-2 bg-white rounded py-6 hover:border-blue-300 mobile:w-4/5 ">
      <span
        className={`save bg-black text-white px-6 py-2 mb-6 -ml-1 inline-block rounded-r-full uppercase font-bold ${props.hideSave}`}
      >
        save {props.savings}%
      </span>
      <div className="px-6">
        <h2 className="font-bold text-lg text-center capitalize">
          {props.header}
        </h2>
        <p className="text-sm text-gray-600 text-center my-3">{props.desc}</p>
        <div className="text-center capitalize">
          <sup className="text-black text-xl">$</sup>
          <span className="font-bold text-3xl text-blue-700">
            {props.price}
          </span>
          <span className="text-gray-500 text-sm">/{props.perWhat}</span>
        </div>
        <div className="py-5 border-solid border-b-2 grid">{props.myItems}</div>
        <div className="flex flex-wrap content-center justify-center">
          <a
            className="btn mt-6 font-bold text-sm text-black border-2 border-black px-8 py-2 uppercase inline-block rounded-full"
            onClick={handleGetNowClick}
            href={`https://client.hostylus.com/cart.php?a=add&pid=${props.productID}`}
          >
            get now
          </a>
        </div>
      </div>
    </div>
  );
}

Product.propTypes = {
  savings: PropTypes.any,
  hideSave: PropTypes.string,
  desc: PropTypes.string,
  header: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  myItems: PropTypes.any.isRequired,
  productID: PropTypes.string.isRequired,
  perWhat: PropTypes.string.isRequired,
};