import React, { useState } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Landing from "../components/landingSection";
import PlanCard from "../components/retrieveWordpressPrice";
import LI from "../components/plan-li";
import Feature from "../components/featureBlock";
import FeatureList from "../components/featureLists";
import Accordion from "../components/accordion";
import Image from "../components/imageProcessing";
// import Packages from "../DB/plan-card";
import Features from "../DB/wordpress-features";
import AccordionContent from "../DB/Accordion-encrypt-protection";
import Background from "../images/Hostylus-and-softylus.png";
import { graphql, useStaticQuery } from "gatsby";
/* eslint-disable no-mixed-spaces-and-tabs */

function wordpressHosting() {
	const data = useStaticQuery(graphql`
		query WordpressQuery {
			allProducts(filter: { productGroupID: { eq: 1 } }) {
				nodes {
					productID
					productGroupID
					name
					description
					monthly
					quarterly
					semiannually
					annually
					biennially
					triennially
				}
			}
		}
	`);
	// console.log(data);
	const [Annualy, setCheck] = useState(true);
	function changeAnnualy() {
		setCheck((prevAnnualy) => !prevAnnualy);
	}
	// let data = "";
	return (
		<Layout>
			<SEO
				keywords={[
					"wordpress hosting",
					"wordpress hosting cost",
					"wordpress hosting free",
					"wordpress hosting plans",
					"wordpress hosting comparison",
					"wordpress hosting sites",
					"wordpress hosting vs web hosting",
					"wordpress hosting providers",
					"dedicated wordpress hosting",
					"wordpress hosting reviews",
					"best wordpress hosting",
					"wordpress hosting packages",
					"wordpress.org hosting",
					"wordpress hosting companies",
					"wordpress hosting options",
					"best wordpress hosting 2021",
					"managed wordpress hosting",
					"wordpress host",
					"wordpress website hosting",
					"best hosting for wordpress",
					"web hosting",
					"wordpress",
					"website hosting",
					"cheap web hosting",
					"wordpress plans",
					"wordpress blog hosting",
					"fast wordpress hosting",
				]}
				title="Wordpress Hosting"
				image={"/hostylus-card-preview.jpg"}
				description={
					"Save time & effort with an easy 1 click installation. Pick your plan & launch your site today with countless tools, features, plus ultra-speed & security."
				}
			/>
			<Landing
				smallHeader="More than content management system"
				header="wordpress hosting"
				desc="Boost your online reach without worrying about your website’s hosting plans. Our wide range of options will help you achieve your intended goals."
				btnURL="#plans"
				btnText={"get started"}
				image={
					<Image
						image={"landing_wordpress_hosting_ztt-aET8s.png"}
						alt={
							"a girl with a laptop in her hands smiling happily about hostylus wordpress hosting"
						}
					/>
				}
				hideSecondary_btn={"hidden"}
			/>
			<section id={"plans"} className=" max-w-4xl mx-auto my-16 mobile:pb-3 ">
				<h2 className="text-4xl text-black font-bold text-center mobile:px-4 tablet:px-6">
					Upgraded plans today for a better tomorrow
				</h2>
				<h4 className="text-gray-400 text-center text-xl px-16 my-3 mobile:px-10">
					Unleash the technology of your mind, and elevate your business sales
					with our wordpressHosting hosting plans
				</h4>
				<div className="grid grid-cols-7 mt-8 mobile:grid-cols-1">
					<h3
						className={`col-span-3 text-right mobile:text-center text-${
							Annualy ? "black" : "gray-400"
						} font-bold text-xl`}
					>
						Bill Annualy
					</h3>
					<label className="px-6 mobile:text-center">
						<input
							id="s2"
							type="checkbox"
							className="switch"
							onClick={changeAnnualy}
						/>
					</label>
					<h3
						className={`col-span-3 text-left mobile:text-center text-${
							Annualy ? "gray-400" : "black"
						} font-bold text-xl`}
					>
						Bill Monthly
					</h3>
				</div>
				<div className="grid grid-cols-3 gap-2 flex justify-items-center mt-6 mobile:grid-cols-1   tablet:grid-cols-1 py-8 ">
					{data.allProducts.nodes.map(function (items, index) {
						var stripedHtml = items.description.replace(/<[^>]+>/g, "");
						var description = stripedHtml.split("\n");
						// let saving=Math.round((items.monthly*12-items.annually)*((items.monthly*12)/100));
						let saving = Math.round(
							(items.monthly - items.annually / 12) *
								(items.monthly / 100) *
								100
						);
						return (
							<PlanCard
								key={index}
								savings={saving}
								header={items.name}
								desc={description[0]}
								price={Annualy ? items.annually / 12 : items.monthly}
								perWhat={"month"}
								productID={items.productID.toString()}
								myItems={
									<ul
										style={{
											listStyleImage: "url('/tick.svg')",
											listStylePosition: "inside",
											justifySelf: "center"
										}}
										className="list-disc mobile:w-11/12 mobile:text-sm"
									>
										{description.slice(1).map((listItem, listIndex) => (
											<LI key={listIndex} LI={listItem} textSize={"text-md"} />
										))}
									</ul>
								}
								hideSave={Annualy ? "" : "hidden"}
							/>
						);
					})}
				</div>
			</section>
			<section className="features max-w-6xl mx-auto my-16 mobile:px-3">
				<h2 className="text-4xl text-black font-bold text-center capitalize">
					your easiest wordPress solutions
				</h2>
				<div className="features grid grid-cols-3 gap-3 flex justify-items-stretch mt-6 mobile:grid-cols-1 tablet:grid-cols-2 py-8 tablet:px-6 laptop:px-6">
					{Features.map((items, key) => (
						<div key={key}>
							<Feature
								image={items.image}
								header={items.title}
								desc={items.description}
								alt={items.title}
							/>
						</div>
					))}
				</div>
			</section>
			<section className="screens my-16 mobile:pb-3">
				<div className="flex mobile:flex-col-reverse tablet:flex-col-reverse laptop:flex-col-reverse my-32 d">
					<div className="pl-32 laptop:pl-0 mobile:px-0.5 tablet:px-0.5 w-1/2 mobile:w-full tablet:w-full laptop:w-full">
						<h2 className="font-bold text-3xl px-16 mobile:text-center mobile:mt-6 tablet:mt-6">
							Build Fast
						</h2>

						<FeatureList
							header="easy installation"
							desc="The one-click installer of WordPress Toolkit does everything from start to finish – downloads WP, creates a DB with a DB user, creates an admin account in WordPress and initializes WordPress so that it’s fully ready for use."
						/>
						<FeatureList
							header="Staging Environment"
							desc="With WordPress Toolkit you can clone your site and set up a staging environment for your experiments, new features and new ideas. You can sync to production when you’re ready."
						/>
						<FeatureList
							header="Theme and Plugin Management"
							desc="Quickly find and install a plugin or theme on a WordPress instance or several instances at once. Activate and deactivate plugins. Bulk remove plugins and themes that are not needed. Install plugin and theme sets on existing sites."
						/>
					</div>
					<div className="wp-images text-right w-1/2 tablet:px-16 laptop:px-16 mobile:px-6 mobile:w-full tablet:w-full laptop:w-full mobile:text-center tablet:text-center laptop:text-center">
						<Image
							image={"build.png"}
							alt={
								" screenshot showing the easy one-click installer of WordPress Toolkit"
							}
						/>
						{/* <img className="ml-2 float-right" src="https://ik.imagekit.io/softylus/browser_obsidian_content-manager_build_OxYgGTwwN.png"/> */}
					</div>
				</div>

				<div className="flex justify-between mobile:grid tablet:grid laptop:grid my-32">
					{/*<img src="https://ik.imagekit.io/softylus/image__2__Y7qhucs8m.png"/>*/}
					<div className="wp-images text-left tablet:px-16 laptop:px-16 mobile:px-6 w-1/2 mobile:w-full tablet:w-full laptop:w-full laptop:w-full mobile:text-center tablet:text-center laptop:text-center">
						{/* <img className="mobile:mr-16" src="https://ik.imagekit.io/softylus/browser_obsidian_content-manager_secure_nAdckg49Y4.png"/> */}
						<Image
							image={"secure.png"}
							alt={
								"a screenshot showing how to secure your website in one click using plesk"
							}
						/>
					</div>
					<div className="pr-16 laptop:pr-0 mobile:px-0.5 w-1/2 mobile:w-full tablet:w-full laptop:w-full">
						<h2 className="font-bold text-3xl px-16 mobile:text-center mobile:mt-6 tablet:mt-6">
							Secure
						</h2>
						<FeatureList
							header="Click Hardening"
							desc="Scan all WordPress sites with Plesk to identify and protect your core installations, no manual work needed. Simply check the items you wish to harden, click “Secure”, and you’re done."
						/>
						<FeatureList
							header="No Security Expertise Needed"
							desc="WordPress Toolkit security scanner goes beyond the basics and implements the latest security recommendations and best practices from WP Codex and WP security experts."
						/>
						<FeatureList
							header="Smart Updates"
							desc="Smart Updates analyzes your WordPress updates, identifies changes and decides whether to perform them, without breaking your production site. Stay updated to prevent security risks."
						/>
					</div>
				</div>
				<div className="flex mobile:flex-col-reverse tablet:flex-col-reverse laptop:flex-col-reverse my-32">
					<div className="pl-32 laptop:pl-0 mobile:px-0.5 tablet:px-0.5 w-1/2 mobile:w-full tablet:w-full laptop:w-full">
						<h2 className="font-bold text-3xl px-16 mobile:text-center mobile:mt-6 tablet:mt-6">
							Run
						</h2>
						<FeatureList
							header="Backup and Restore Points"
							desc="If something goes wrong, restore points and backups allow you to restore your site back to its previous state."
						/>
						<FeatureList
							header="Debug Management"
							desc="Manage all important debug options in WordPress or manage debug options on a per-instance basis from a single interface."
						/>
						<FeatureList
							header="Site Indexing"
							desc="Enable or disable search engines from indexing your site on a per-instance basis."
						/>
						<FeatureList
							header="Maintenance Mode in WordPress Toolkit"
							desc="Activate WordPress maintenance mode when updating WordPress, plugins, or themes with a single click."
						/>
						<FeatureList
							header="Command Line Interface"
							desc="Easily access WordPress Command Line Interface for all your WordPress instances. Import a database, create a new user, update themes and plugins using WP-CLI. Access a full range of cloning options with Cloning CLI."
						/>
					</div>
					<div className="wp-images text-right w-1/2 tablet:px-16 laptop:px-16 mobile:px-6 mobile:w-full tablet:w-full laptop:w-full laptop:w-full mobile:text-center tablet:text-center laptop:text-center ">
						<Image
							image={"run.png"}
							alt={
								" a screenshot showing the easy interface to manage wordpress websites"
							}
						/>
						{/* <img className="ml-2 float-right" src="https://ik.imagekit.io/softylus/browser_obsidian_content-manager_run_MyHamMOOQ.png"/> */}
					</div>
					{/*<img className="" src="https://ik.imagekit.io/softylus/right_screen_Pvjrjqghg.png"/>*/}
				</div>
			</section>
			<section
				style={{ backgroundImage: `url(${Background})` }}
				className="Epic h-96 py-16"
			>
				<div className="grid justify-items-center">
					<h2 className="mobile:text-2xl text-center text-white text-4xl font-bold">
						What Makes Us Your Pick?
					</h2>
					<p className="mobile:px-5 tablet:px-6 mobile:text-lg px-64 py-4 text-center text-white text-xl laptop:px-16 ">
						We’re capable of giving you the full support you’ll need with your
						WordPress website from scratch with the help of our partner company
						that skillfully covers this part and more.
					</p>
					<img
						className=" mt-5 mobile:px-8 w-1/3 mobile:w-full tablet:w-2/3 laptop:w-2/3"
						src={"/Softylus-and-hostylus.png"}
						alt="a blue image with a white text that show why hostylus is a good pick"
					/>
				</div>
			</section>
			<section className="acc py-16 grid justify-items-center">
				<div className=" px-8 max-w-2xl">
					<h2 className="capitalize text-center text-black mb-16 text-4xl font-bold uppercase">
						Entery-level Let’s encrypt Protection
					</h2>
					{AccordionContent.map((items, index) => (
						<Accordion
							key={index}
							title={items.title}
							content={items.content}
						/>
					))}
				</div>
			</section>
		</Layout>
	);
}
export default wordpressHosting;
