export default [
  {
    title: "HSTS",
    content:
      "Enhances the security of website’s visitors by prohibiting web browsers from accessing the website via insecure HTTP connections. If visitors are unable to connect via HTTPS, your website will become unavailable.",
  },
  {
    title: "OCSP Stapling",
    content:
      "Enhances the privacy of website’s visitors and improves the website performance. The web server will request the status of the website’s certificate (can be good, revoked, or unknown) from the CA instead of the visitor’s browser doing so.",
  },

  {
    title: "SSL Labs Test",
    content: "Deep analysis of the SSL web server configuration.",
  },

  {
    title: "Keep websites secured",
    content:
      "Automatically replaces expired or self-signed SSL/TLS certificates with free valid certificates from Let’s Encrypt. Covers each domain, subdomain, domain alias, and webmail belonging to the subscription.",
  },
];
